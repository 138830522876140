import request from '@/utils/request'
export function userInfo(data) {
	return request({
		url: '/api/user/index',
		method: 'post',
		data
	})
}
// 修改密码
export function changePwd(data) {
	return request({
		url: '/api/user/changePwd',
		method: 'post',
		data
	})
}
// 绑定微信
export function wechatQrcode(data) {
	return request({
		url: '/api/wechat/qrcode',
		method: 'post',
		data
	})
}
export function wechatCheckUserScan(data) {
	return request({
		url: '/api/wechat/checkUserScan',
		method: 'post',
		data
	})
}
// 解绑微信
export function wechatUnBindWx(data) {
	return request({
		url: '/api/wechat/unBindWx',
		method: 'post',
		data
	})
}