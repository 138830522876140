<template>
	<a-layout>
		<a-layout-sider theme="light" width="270">
			<Aside />
		</a-layout-sider>
		<a-layout class="container-center">
			<div class="container">
					<keep-alive>
						<router-view
							v-if="$route.meta.keepAlive"
							:key="$route.path"
							ref="content"
						/>
					</keep-alive>
					<router-view
						v-if="!$route.meta.keepAlive"
						ref="content"
						:key="$route.path"
					/>
				</div>
		</a-layout>
	</a-layout>
</template>

<script>
import Aside from '@/components/Aside/Aside.vue'
export default {
	components: {
		Aside
	},
	data() {
		return {
			collapsed: false
		}
	}
}
</script>

<style lang="less" scoped>
// .container {
// 	flex: 1;
// 	height: calc(100vh - 20px);
//     padding: 16px;
//     overflow-y: auto;
// }
</style>