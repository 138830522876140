export const routes = [
	{
		path: '/index',
		title: '首页',
		icon: 'icon-home'
	},
	{
		path: '/videoManagement/index',
		title: '视频号管理',
		icon: 'icon-authorize'
	},
	{
		path: 'video',
		title: '视频投放',
		icon: 'icon-mvideo',
		children: [
			{
				path: '/hotManage/planPackage/video',
				title: '计划包'
			},
			{
				path: '/hotManage/create',
				title: '新建投放'
			},
			{
				path: '/hotManage/planList/video',
				title: '投放记录'
			}
		]
	},
	{
		path: 'live',
		title: '直播投放',
		icon: 'icon-mlive',
		children: [
			{
				path: '/hotManage/planPackage/live',
				title: '计划包'
			},
			{
				path: '/liveManage/create',
				title: '新建投放'
			},
			{
				path: '/hotManage/planList/live',
				title: '投放记录'
			}
		]
	},
	{
		path: '/dataManage/index',
		title: '数据报表',
		icon: 'icon-monitoring'
	},
	{
		path: '/aiHelper',
		title: '智能助手',
		icon: 'icon-ai',
		children: [{
			path: '/aiHelper',
			title: '亏损关停'
		}, {
			path: '/highAgain',
			title: '高产复投'
		}, 
		// {
		// 	path: '/lowStop',
		// 	title: '低消关停'
		// }
	]
	},
	{
		path: '/teamManagement/index',
		title: '团队管理',
		icon: 'icon-team',
		roles: [0]
	},
	{
		path: '/earlyWarning/index',
		title: '预警通知',
		icon: 'icon-warning',
		roles: [0]
	},
	{
		path: '/accountSet/index',
		title: '账号设置',
		icon: 'icon-setting'
	},
]