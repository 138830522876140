/*
 * @Author: 八点半的星光
 * @Date: 2023-05-18 14:40:19
 * @LastEditTime: 2023-05-19 10:12:38
 * @LastEditors: Please set LastEditors
 * @Description: 
 */
import Vue from "vue"

import Router from "vue-router"

import LoginLayout from '@/layouts/loginLayout.vue'
import BasicLayout from '@/layouts/BasicLayout'

const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

const routes = [
	// 登录相关
	{
		path: '/login',
		component: LoginLayout,
		redirect: '/login',
		hidden: true,
		meta: {
			title: '登录'
		},
		children: [{
			path: '/login',
			name: 'Login',
			component: () => import('@/views/system/login'),
			meta: {
				title: '登录'
			}
		}, {
			path: '/codelogin',
			name: 'CodeLogin',
			component: () => import('@/views/system/codeLogin'),
			meta: {
				title: '验证码登录'
			}
		}]
	},
	{
		path: '/',
		component: BasicLayout,
		redirect: '/index',
		hidden: true,
		meta: {
			title: '首页',
			icon: 'icon-home'
		},
		children: [{
			path: '/index',
			name: 'Index',
			component: () => import('@/views/index'),
			meta: {
				title: '首页',
				keepAlive: false
			}
		}]
	},
	// 视频号管理
	{
		path: '/videoManagement/index',
		component: BasicLayout,
		redirect: '/videoManagement/index',
		meta: {
			title: '视频号管理',
			icon: 'icon-home'
		},
		children: [{
			path: '/videoManagement/index',
			name: 'VideoManagement',
			component: () => import('@/views/videoManagement/index'),
			meta: {
				title: '视频号管理',
				keepAlive: false
			}
		}]
	},
	{
		path: '/dataManage/index',
		component: BasicLayout,
		redirect: '/dataManage/index',
		meta: {
			title: '数据报表',
			icon: 'icon-monitoring'
		},
		children: [{
			path: '/dataManage/index',
			name: 'DataManagementIndex',
			component: () => import('@/views/dataManage/index'),
			meta: {
				title: '数据报表',
				keepAlive: false
			}
		}]
	},
	// 加热监控
	{
		path: '/hotMonitor',
		component: BasicLayout,
		redirect: '/hotManage/create',
		meta: {
			title: '加热监控'
		},
		children: [{
			path: '/hotManage/create',
			name: 'HotCreate',
			component: () => import('@/views/hotMonitorManage/createHotPlan'),
			meta: {
				title: '加热投放'
			}
		}, {
			path: '/hotManage/planPackage/:type?',
			name: 'HotPlanPackage',
			component: () => import('@/views/hotMonitorManage/planPackage'),
			meta: {
				title: '计划包'
			}
		}, {
			path: '/hotManage/planList/:type?',
			name: 'PlanList',
			component: () => import('@/views/hotMonitorManage/planList'),
			meta: {
				title: '投放记录'
			}
		}]
	},
	{
		path: '/aiHelper',
		component: BasicLayout,
		redirect: '/aiHelper',
		meta: {
			title: '智能助手'
		},
		children: [{
			path: '/aiHelper',
			name: 'AiHelper',
			component: () => import('@/views/AiHelperManage/index'),
			meta: {
				title: '智能助手'
			}
		}, {
			path: '/lowStop',
			name: 'AiHelperLowStop',
			component: () => import('@/views/AiHelperManage/lowStop'),
			meta: {
				title: '低消关停',
				isClose: true, // 功能关闭
			}
		}, {
			path: '/highAgain',
			name: 'AiHelperHigAgain',
			component: () => import('@/views/AiHelperManage/highAgain'),
			meta: {
				title: '高产复投',
				// isClose: true, // 功能关闭
			}
		}]
	},
	// 团队管理
	{
		path: '/teamManagement/index',
		component: BasicLayout,
		redirect: '/teamManagement/index',
		meta: {
			title: '团队管理',
			icon: 'icon-team',
			roles: [0]
		},
		children: [{
			path: '/teamManagement/index',
			name: 'TeamManagement',
			component: () => import('@/views/teamManagement/index'),
			meta: {
				title: '团队管理',
				keepAlive: false,
				roles: [0]
			}
		}]
	},
	// 预警通知
	{
		path: '/earlyWarning/index',
		component: BasicLayout,
		redirect: '/earlyWarning/index',
		meta: {
			title: '预警通知',
			icon: 'icon-warning'
		},
		children: [{
			path: '/earlyWarning/index',
			name: 'EarlyWarning',
			component: () => import('@/views/earlyWarning/index'),
			meta: {
				title: '预警通知',
				keepAlive: false,
				roles: [0]
			}
		}]
	},
	// 账号设置
	{
		path: '/accountSet/index',
		component: BasicLayout,
		redirect: '/accountSet/index',
		meta: {
			title: '账号设置',
			icon: 'icon-setting'
		},
		children: [{
			path: '/accountSet/index',
			name: 'AccountSet',
			component: () => import('@/views/accountSet/index'),
			meta: {
				title: '账号设置',
				keepAlive: false
			}
		}]
	},
	// 直播加热
	{
		path: '/liveManage',
		component: BasicLayout,
		redirect: '/liveManage/create',
		children: [{
			path: '/liveManage/create',
			name: 'LiveManageCreate',
			component: () => import('@/views/liveManage/create'),
			meta: {
				title: '新建投放'
			}
		}]
	}

]

const router = new Router({
    mode: 'history',
    routes,
})

export default router



