import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './core/lazy_useUI'
import DhIcon from '@/components/DhIcon/DhIcon'
import request from '@/utils/request'
import store from './store'
import Cookies from 'js-cookie'
import Global from '@/utils/global'
import * as echarts from 'echarts'
import PullTo from 'vue-pull-to'
Vue.component('dh-icon', DhIcon)
import './global.less'
import './permission'
import './utils/filter'
import common from '@/utils/common'
import './assets/icons/index'

Vue.config.productionTip = false
Vue.prototype.$http = request
Vue.prototype.$cookieSave = Cookies
Vue.prototype.Global = Global
Vue.prototype.$echarts = echarts
Vue.prototype.$common = common

// 全局引用的组件
import ContHeader from '@/components/ContHeader'
Vue.component('ContHeader', ContHeader)
Vue.component('PullTo', PullTo)
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
