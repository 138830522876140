import axios from "axios";
import md5 from "js-md5";
import qs from "qs";
import CryptoJS from "crypto-js";
import router from '@/router'
import { message } from "ant-design-vue";
import { getToken, getEcode, removeEcode } from "@/utils/auth";
import LoadAnimation from "@/load/loadAnimation";

const loadAni = new LoadAnimation();
let customConfig = null;

const service = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? 'https://api.xiaoredou.com' : 'http://littlehotcoin-api.n4.dh-tech.cn',
    timeout: 100000, // 设置请求超时
    headers: {
        // "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    // transformRequest: (data) => {
    //     return qs.stringify(data);
    // },
});
const version = "1.0";
const signKey = process.env.NODE_ENV === 'production' ? "io86m8FzU7Pb":"DSkghziGJM8IZyAH";
const encrypt = 2;
const device = "web";
let token = "";
let ecode = ''
// 请求拦截器
service.interceptors.request.use(
    async (config) => {
        customConfig = config;
        if (config.isShowLoading) {
            loadAni.show();
        }
        token = (await getToken()) || "";
        ecode = (await getEcode()) || ''
        
        const time = Date.parse(new Date()) / 1000;
        const custom = Math.random().toString(36).substr(2);
        const c = config.url;
        let url = "";
        if (c.search("&") !== -1) {
            url = c.substring(0, c.indexOf("&"));
        } else {
            url = c;
        }
        let str =
            signKey +
            url.toLowerCase() +
            time +
            device +
            version +
            token +
            custom;
        let sign = md5(str);
        config.headers = {
            ...config.headers,
            version,
            device,
            token,
            encrypt,
            custom,
            sign,
            time
        };
        if(ecode) {
            config.headers.ecode = ecode
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
// 响应拦截器
service.interceptors.response.use(
    (response) => {
        if (customConfig.isShowLoading) {
            loadAni.hide();
        }
        const res = response.data;
        const iv = CryptoJS.enc.Hex.parse(md5("key_" + res.time));
        const option = { iv: iv, padding: CryptoJS.pad.Pkcs7 };
        if (res.code == 0) {
            if (res.encrypt === 1 && res.data) {
                let newKey = md5(res.sign);
                let key = CryptoJS.enc.Hex.parse(newKey);
                let decrypted = CryptoJS.AES.decrypt(res.data, key, option);
                let string = decrypted.toString(CryptoJS.enc.Utf8);
                try {
                    res.data = JSON.parse(string);
                } catch (e) {
                    res.data = string;
                }
            }
            return res;
        } else {
            if (res.code == 401) {
                removeEcode()
                message.error({
                    content: "请登录后操作！",
                    duration: 3,
                });
                setTimeout(() => {
                    router.push('/login')
                }, 500);
            } else if (res.data?.refresh) { // 处理一键登录不同账号的情况
                router.go(0) // 重新加载当前页
            } else {
                message.error({
                    content: res.msg || "系统繁忙，请稍后重试！",
                    duration: 3,
                });
            }
        }
        
    },
    (error) => {
        if (customConfig.isShowLoading) {
            loadAni.hide();
        }
    }
);
export { service };
export default service;
