<template>
    <a-config-provider :locale="zhCN">
        <div id="app">
            <router-view />
        </div>
    </a-config-provider>
</template>

<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
    name: "App",
    data() {
        return {
            zhCN,
        };
    },
};
</script>
<style>
.ant-form-item {
    margin-bottom: 15px !important;
}
.ant-modal-header,
.ant-modal-footer {
    border: none !important;
}
.ant-modal-title {
    font-size: 20px !important;
    color: #333 !important;
    line-height: 50px !important;
}
.ant-modal-close-x {
    font-size: 18px !important;
    margin-top: 10px;
}
.ant-tabs-bar {
    border: 0 !important;
}
.progress .ant-popover-inner {
    background-color: #52c41a;
}
.progress .ant-popover-arrow {
    border-right-color: #52c41a !important;
    border-bottom-color: #52c41a !important;
}
.progress .ant-popover-inner-content{
  color: #fff;
}
.offline .ant-popover-inner {
    background-color: #ff3b30;
}
.offline .ant-popover-arrow {
    border-right-color: #ff3b30 !important;
    border-bottom-color: #ff3b30 !important;
}
.offline .ant-popover-inner-content{
  color: #fff;
}
</style>
