import request from '@/utils/request'
// 登录
export function userLogin(data) {
	return request({
		url: '/api/user/login',
		method: 'post',
		data,
		// isShowLoading: true
	})
}
// 退出登录
export function userLogout(data) {
	return request({
		url: '/api/user/logout',
		method: 'post',
		data
	})
}

// 万能代理
export function wxProxy(data, isShowLoading) {
	return request({
		url: '/api/weixin/proxy',
		method: 'post',
		data,
		isShowLoading
	})
}

// 策略指标
export function indicatorList(data) {
	return request({
		url: '/api/common/indicator',
		method: 'post',
		data
	})
}
// 自定义配置
export function userConfig(data) {
	return request({
		url: '/api/user_config/config',
		method: 'post',
		data
	})
}
// 自定义配置编辑
export function configEdit(data) {
	return request({
		url: '/api/user_config/edit',
		method: 'post',
		data
	})
}
// 公共配置
export function commonConfig(data) {
	return request({
		url: '/api/common/config',
		method: 'post',
		data
	})
}

// 省市区
export function areaTree(data) {
	return request({
		url: '/api/common/areaTree',
		method: 'post',
		data
	})
}

// 兴趣列表

export function tagV3Tree(data) {
	return request({
		url: '/api/common/tagTree',
		method: 'post',
		data
	})
}
// 导出任务状态
export function commonExport(data) {
	return request({
		url: '/api/common/export',
		method: 'post',
		data
	})
}
// 下载
export function commonExportDownload(data) {
	return request({
		url: '/api/common/exportDownload',
		method: 'post',
		data
	})
}

// 发送验证码
export function sendCode(data) {
	return request({
		url: '/api/sms/send',
		method: 'post',
		data
	})
}

// 用户协议
export function userProtocol(data) {
	return request({
		url: '/api/renew/article',
		method: 'post',
		data
	})
}
