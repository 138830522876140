import Cookies from 'js-cookie'

const TokenKey = 'xrd_token'
const ConfigKey = 'xrd_config'
const EcodeKey = 'xrd_ecode'


export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
export function getConfig() {
  return Cookies.get(ConfigKey)
}
export function setConfig(info) {
  return Cookies.set(ConfigKey, info)
}
export function removeConfig() {
  return Cookies.remove(ConfigKey)
}

export function setEcode(value) {
  return Cookies.set(EcodeKey, value)
}

export function getEcode() {
  return Cookies.get(EcodeKey)
}
export function removeEcode() {
  return Cookies.remove(EcodeKey)
}