<template>
    <a-layout-header class="page-header-inner" v-if="show">
        <div class="flex-sub flex align-center">
            <template v-if="tabs.length">
                <a-tabs :class="[type === 'interRadar' ? 'interRadar' : '']" v-model="tabType" @change="changeTab">
                <a-tab-pane :key="index + 1" :tab="item" v-for="(item, index) in tabs"></a-tab-pane>
                </a-tabs>
            </template>
            <template v-else>
                <h1 v-if="back">
                    <a class="title" @click="onBack"
                        ><a-icon v-if="notOnly" class="icon" type="left" />{{
                            title || currentName
                        }}</a
                    >
                </h1>
                <h1 class="title" v-else>{{ title || currentName }}</h1>
            </template>
        </div>
    </a-layout-header>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: true,
        },
        back: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "",
        },
        type: {
            type: String,
            default: ''
        },
        curTab: {
            type: [String, Number],
            default: 1
        },
        tabs: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            tabType: 1,
            currentName: "",
            notOnly: window.history.length > 1,
        };
    },
    computed: {},
    watch: {
        $route: {
            handler(val, oldVal) {
                this.getRouteName(val);
            },
            // 深度观察监听
            deep: true,
        },
        msgCount() {
            this.count = this.msgCount;
        },
        msgList() {
            this.list = this.msgList;
        },
        curTab () {
            this.tabType = this.curTab
        },
    },
    mounted() {
        this.tabType = this.curTab
        this.getRouteName(this.$route);
    },
    methods: {
        setType (type) {
            this.tabType = type
        },
        changeTab () {
            this.$emit('change', this.tabType)
        },
        getRouteName(item) {
            this.currentName = item.meta.title;
        },
        onBack() {
            this.$router.go(-1);
        },
    },
};
</script>

<style lang="less" scoped>
.page-header-inner{
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
  margin-top: 56px;
  .title{
    font-size: 24px;
    color: rgba(0,0,0,.9);
    font-weight: 500;
    line-height: 32px;
  }
}
/deep/ .ant-tabs {
  &-bar {
    margin-bottom: 0;
    border: 0;
  }
  .ant-tabs-tab {
    font-size: 16px;
    padding: 12px 0;
  }
  .ant-tabs-ink-bar {
    height: 3px;
  }
}
</style>
