import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import user from './modules/user'
import config from './modules/config'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user,
    config
  },
  getters,
  plugins: [createPersistedState({
    // storage: window.sessionStorage
  })]
})

export default store