const global = {
  install: function (Vue) {
    Vue.prototype.$common = this
  },
  // 深度克隆对象
  deepClone (target) {
    let result
    if (typeof target === 'object') {
      if (Array.isArray(target)) {
        result = []
        for (const i in target) {
          result.push(this.deepClone(target[i]))
        }
      } else if (target === null) {
        result = null
      } else if (target.constructor === RegExp) {
        result = target
      } else {
        result = {}
        for (const i in target) {
          result[i] = this.deepClone(target[i])
        }
      }
    } else {
      result = target
    }
    return result
  },
  
  // 四舍五入保留2位小数（若第二位小数为0，则保留一位小数）
  keepTwoDecimal (num) {
    if (isNaN(num)) num = 0
    var result = parseFloat(num)
    if (isNaN(result)) {
      return false
    }
    result = Math.round(num * 100)
    return result
  },
  /* 创建一个a标签，并做下载点击事件 */
  downloadFile (hrefUrl, fileName) {
    var a = document.createElement('a')
    a.setAttribute('href', hrefUrl)
    a.setAttribute('download', fileName)
    a.setAttribute('target', '_blank')
    const clickEvent = document.createEvent('MouseEvents')
    clickEvent.initEvent('click', true, true)
    a.dispatchEvent(clickEvent)
  },

}

export default global
