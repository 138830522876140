import moment from 'moment'
import 'moment/locale/zh-cn'
import { TreeSelect } from 'ant-design-vue';
moment.locale('zh-cn')
const GLOBAL_METHOD = {
	formateTime: (time, pattern = 'YYYY-MM-DD HH:mm:ss') => {
		if (!time) return '-'
		return moment(time).format(pattern)
	},
	dateRange: (type, pattern = 'YYYY-MM-DD HH:mm:ss') => {
		//开始时间
		let start = ''
		//结束时间
		let end = ''
		let weekOfday = parseInt(moment().format('d'))
		switch (type) {
		  case 'today':
			start = moment().format(pattern)
			end = moment().format(pattern)
			break;
		  case 'yesterday':
			start = moment().subtract(1, 'days').format(pattern)
			end = moment().subtract(1, 'days').format(pattern)
			break;
		  case 'sevenDay': // 近7天
			start = moment(moment().subtract(6, 'days')).format(pattern)
			end = moment().format(pattern)
			break;
		  case 'week': // 本周
			start = moment().week(moment().week()).startOf('week').format(pattern)
			end = moment().week(moment().week()).endOf('week').format(pattern)
			break;
		  case 'lastWeek': // 上周
			start = moment().subtract(weekOfday + 7, 'days').format(pattern)
			end = moment().subtract(weekOfday + 1, 'days').format(pattern)
			break;
		  case 'month': // 本月
			start = moment().startOf('month').format(pattern)
			end = moment().format(pattern)
			break;
		  case 'lastMonth': // 上月
			start = moment().subtract(1, 'month').startOf('month').format(pattern)
			end = moment().subtract(1, 'month').endOf('month').format(pattern)
			break;
		  case 'thirtyDay': // 近30天
			start = moment().subtract(29, 'days').format(pattern)
			end = moment().format(pattern)
			break;
		}
		return {start, end}
	},

	toMomentTime(time, pattern = 'HH:mm:ss') { // 用于日期时间选择器传递默认时间
		return moment(time, pattern)
	},
	deleteEmptyProperty: (object) => { // 删除对象中的空值 空数组 空对象
		for (var i in object) {
			var value = object[i]
			if (typeof value === 'object') {
				if (Array.isArray(value)) {
					if (value.length === 0) {
						delete object[i]
						continue
					} else {
						object[i] = object[i][object[i].length - 1]
					}
				}
				this.deleteEmptyProperty(value)
				if (this.isEmpty(value)) {
					delete object[i]
				}
			} else {
				if (value === '' || value === null || value === undefined) {
					delete object[i]
				} else {}
			}
		}
	},
	formatPhone (phone) {
		if (!phone) return ''
		const reg = /^(\d{3})\d{4}(\d{4})$/
		return phone.replace(reg, '$1****$2')
	},

	changeMetaTitle (context, title) {
		context.$route.meta.title = title
	},

	selectFilterOption (input, option) {
		return (
			option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		)
	},
	showParent: (() => {
		return TreeSelect.SHOW_PARENT
	})(),

	on: (function () {
		if (document.addEventListener) {
			return function (element, event, handler) {
				if (element && event && handler) {
					element.addEventListener(event, handler, false)
				}
			}
		} else {
			return function (element, event, handler) {
				if (element && event && handler) {
					element.attachEvent('on' + event, handler)
				}
			}
		}
	})(),
	off: (function () {
		if (document.removeEventListener) {
			return function (element, event, handler) {
				if (element && event) {
					element.removeEventListener(event, handler, false)
				}
			}
		} else {
			return function (element, event, handler) {
				if (element && event) {
					element.detachEvent('on' + event, handler)
				}
			}
		}
	})()
}

export default GLOBAL_METHOD
