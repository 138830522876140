<template>
	<my-icon :type="type" />
  </template>
  
  <script>
  import MyIcon from './index'
  export default {
	components: { MyIcon },
	props: {
	  type: {
		type: String,
		required: true
	  }
	}
  }
  </script>