import Vue from 'vue'
import router from './router'
import { setDocumentTitle } from './utils/index'
import { getToken, setToken, setEcode } from './utils/auth'
import store from './store/index'

// 登录白名单
const whiteList = ['/login', '/codeLogin']

router.beforeEach((to, from, next) => {
	let token = to.query.t || ''
	let ecode = to.query.e || ''
	if(token) { // 携带token，后台一键登录到首页
		setToken(token)
		setEcode(ecode)
		store.dispatch('user/getUserInfo').then(() => {}).catch(() => {})
		return next('/index')
	}
	let role_id = store.getters.info.role_id
	let roles = to.meta.roles
	if(to.meta && to.meta.isClose) { // 功能关闭
		Vue.prototype.$message.info({
			content: '功能正在开发中'
		})
		return
	}
	to.meta && (typeof to.meta.title !== 'undefined' && setDocumentTitle(`${to.meta.title}`))
	// 白名单直接进入
	if(whiteList.includes(to.path)) {
		next()
		return
	}
	// 无token，去登录
	if(!getToken()) {
		next('/login')
	} else {
		// 判断用户权限，有 则进 否 提示
		if(!roles || !roles.length || roles.includes(role_id)) {
			next()
		} else {
			Vue.prototype.$message.info({
				content: '暂无权限'
			})
		}
	}
	
})