/*
 * @Author: 八点半的星光
 * @Date: 2023-04-21 10:59:06
 * @LastEditTime: 2023-05-04 09:53:07
 * @LastEditors: Please set LastEditors
 * @Description: 
 */
import { userLogin, userLogout } from "@/api/common";
import { setToken, removeToken, getToken, removeEcode } from "@/utils/auth";
import { userInfo } from "@/api/account";

const state = {
	token: "",
	info: ''
}

const mutations = {
	SET_TOKEN: (state, token) => {
		state.token = token;
	},
	SET_INFO: (state, info) => {
		state.info = info
	},
}

const actions = {
	result({ commit }, { response }) {
		let userinfo = response.data
		commit('SET_TOKEN', userinfo.token)
		commit('SET_INFO', userinfo)
		setToken(userinfo.token)
	},
	// user login
	login({ dispatch }, params) {
		removeToken()
		return new Promise((resolve, reject) => {
			userLogin(params).then(response => {
				if(response.encrypt == 1 && response.data) {
					dispatch('result', { response })
					resolve(response)
				}
			}).catch(error => {
				reject(error)
			})
		})
	},
	logout({commit, state}) {
		return new Promise((resolve) => {
			userLogout().then(response => {
				if(response.code == 0) {
					commit('SET_TOKEN', null)
					commit('SET_INFO', {})
					removeToken()
					removeEcode()
					resolve();
				}
				
			})
            
        });
	},
	clearData({ commit }) {
        return new Promise((resolve) => {
			commit('SET_TOKEN', null)
			commit('SET_INFO', {})
			removeToken()
			removeEcode()
            resolve();
        });
    },
	getUserInfo({ commit }) {
		let token = getToken() || ''
		return new Promise((resolve, reject) => {
			userInfo().then(response => {
				if(response.code == 0 && response.data) {
					response.data.token = token
					commit('SET_TOKEN', token)
					commit('SET_INFO', response.data)
					resolve(response)
				}
			}).catch(error => {
				reject(error)
			})
		})
	}
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
