<template>
	<div class="aside">
		<div class="side-header">
			<div class="flex side-bar-header align-center justify-center">
				<div class="logo">
					<img src="../../assets/images/logo.png" alt="">
				</div>
			</div>
		</div>
		<div class="menu font16">
			<a-menu mode="inline" :open-keys="openKeys" @openChange="onOpenChange" :defaultSelectedKeys="defaultSelectedKeys" v-model="defaultSelectedKeys">
				<!-- 首页 -->
				<template v-for="item in menuList">
					<template v-if="!item.hidden">
						<a-sub-menu :key="item.path" v-if="item.children" class="font16">
							<span slot="title">
								<i :class="['iconfont', item.icon]"></i>
								<span>{{ item.title }}</span>
							</span>
							<template>
								<a-menu-item v-for="subItem in item.children" :key="subItem.path">
									<router-link :to="{ path: subItem.path }">
										{{ subItem.title }}
									</router-link>
								</a-menu-item>
							</template>
						</a-sub-menu>
						<template v-else>
							<a-menu-item :key="item.path"  class="flex font16 align-center">
								<i :class="['iconfont', item.icon]"></i>
								<router-link :to="{ path: item.path }">
									{{ item.title }}
								</router-link>
							</a-menu-item>
						</template>
					</template>
				</template>
			</a-menu>
		</div>
		<div class="footer">
			<ul class="footer-list">
				<li class="flex footer-list-item align-center justify-between" :class="{active: showPopup}" @click.stop="showLogout">
					<div class="flex footer-title align-center">
						<img class="avatar" v-if="userInfo.avatar" :src="userInfo.avatar" alt="">
						<img class="avatar" v-if="!userInfo.avatar" src="@/assets/images/icon.png" alt="" />
						<div class="title font16 text-line-1">{{ userInfo.nickname }}</div>
					</div>
					<a-icon type="right" style="color: #cccccc"/>
					<ul class="sub-menu-list" v-if="showPopup">
						<li class="sub-menu-item" @click="handleLogout">退出登录</li>
					</ul>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { routes } from '@/utils/routes.js'
import { commonConfig } from "@/api/common";
function filterList(list, roleId) {
	const newRoutes = list.filter(item => {
		if (!item.roles || !item.roles.length || item.roles.includes(roleId)) {
			if (item.children?.length) {
				item.children = filterList(item.children) // 递归处理子节点
			}
			return item
		}
	})
	return newRoutes
}
export default {
	data () {
		return {
			defaultSelectedKeys: [this.$route.path],
			openKeys: [this.$route.path],
			menuList: routes,
			showPopup: false
		}
	},
	computed: {
		userInfo() {
			return this.$store.getters.info
		},
		configData() {
            return this.$store.getters.config;
        }
	},
	mounted() {
		// if(this.userInfo.role_id!=0){
		// 	this.menuList = this.menuList.filter(item=>item.path!='/teamManagement/index'&&item.path!='/earlyWarning/index')
		// }
		setTimeout(() => {
			this.menuList = filterList(this.menuList, this.userInfo.role_id)
		}, 0)
		
		addEventListener('click', e => {
			if(!(e.target.classList && e.target.classList.contains('sub-menu-list'))) {
				this.showPopup = false
			}
		})
		this.getConfig()
	},
	methods: {
		onOpenChange(openKeys) {
			if(openKeys && openKeys.length) {
				this.openKeys= [openKeys[openKeys.length - 1]]
			} else {
				this.openKeys = ['']
			}
		},
		showLogout() {
			this.showPopup = !this.showPopup
		},
		handleLogout() {
			this.$store.dispatch('user/logout').then(res => {
				this.$store.dispatch('config/clearData')
				this.$message.success('退出成功')
				this.$router.replace('/login')
			})
		},
		getConfig(){
			commonConfig({
				hash:this.configData?.hash||""
			}).then(res => {
				if(res&&res.code==0&&res.data){
					this.$store.dispatch("config/getConfig", res.data);
				}
            })
		}
	},
	watch: {
		$route: {
			handler (val, oldVal) {
				this.defaultSelectedKeys = [val.path]
			},
			// // 深度观察监听
			// deep: true
		}
	}
}
</script>

<style scoped lang="less">
.aside {
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: space-between;
	align-items: center;

}
.side-header {
	margin: 0;
    padding: 42px 20px 60px;
	.side-bar-header {
		.logo {
			width: 180px;
			img {
				display: block;
				width: 100%;
			}
		}
	}
}
.menu {
	flex: 1;
	width: 100%;
	padding: 0 10px 10px;
	overflow-x: hidden;
	overflow-y: auto;
	box-sizing: border-box;
	::v-deep .ant-menu-inline {
		border:none;
		.ant-menu-item {
			height: 50px;
			line-height: 50px;
			font-size: 18px;
			color: #222222;
			border-radius: 5px;
			.anticon {
				font-size: 22px;
			}
			&:not(.ant-menu-item-selected):hover {
				background: #f5f5f5;
				color: #222222;
				i {
					color: @theme-color;
				}
			}
		}
		.ant-menu-sub {
			.ant-menu-item {
				padding-left: 55px !important;
			}
			.ant-menu-item:last-child{
				margin-bottom: 10px;
			}
		}
		.ant-menu-submenu-title {
			font-size: 18px;
			height: 50px;
			line-height: 50px;
			&>span {
				display: flex;
				align-items: center;
			}
			.anticon {
				font-size: 22px;
			}
			.ant-menu-submenu-arrow {
					&:after, &:before {
						background: #cccccc;
					}
			}
			&:hover {
				.ant-menu-submenu-arrow {
					&:after, &:before {
						background: @theme-color;
					}
				}
			}
		}
		.ant-menu-item-selected {
			color: @theme-color;
			&:after {
				opacity: 0;
			}
		}
	}
	&-list {
		width: 100%;
		&-item {
			padding: 15px 10px;
			border-radius: 4px;
			color: #333;
			cursor: pointer;
			&.active {
				background: @theme-color-light;
				color: @theme-color;
			}
			&:hover {
				background: #f2f2f2;
				color: #333;
			}
		}
	}
}
.iconfont {
	margin-right: 10px;
	font-size: 22px;
}
.footer {
	width: 100%;
	padding: 10px;
	border-top: 1px solid #f5f5f5;
	&-list {
		width: 100%;
		margin: 0;
		&-item {
			position: relative;
			line-height: 50px;
			padding: 0 10px;
			border-radius: 5px;
			cursor: pointer;
			&:hover {
				background: #f5f5f5;
			}
			.footer-title {
				.avatar {
					display: flex;
					width: 30px;
					height: 30px;
					margin-right: 10px;
					border-radius: 50%;
				}
			}
			&.active {
				color: @theme-color;
				background: @primary-bg-color;
			}
			.sub-menu-list {
				background: #fff;
				border: 1px solid rgba(0,0,0,.08);
				box-shadow: 0 4px 6px 0 rgba(0,0,0,.1);
				position: absolute;
				right: -20px;
				bottom: 0;
				z-index: 1000;
				transform: translateX(100%);
				width: 168px;
				padding: 5px;
				box-sizing: border-box;
				transition: right .3s ease-in-out;
				border-top-right-radius: 8px;
				border-bottom-right-radius: 8px;
				z-index: 10000;
				.sub-menu-item {
					padding: 0 10px;
					color: #333;
					&:hover {
						background: #f5f5f5;
						border-radius: 6px;
					}
				}
			}
		}
	}
}

</style>
