import { setConfig, getConfig } from "@/utils/auth";
const state = {
	configInfo: getConfig()||{},
    tagInfo: {},
    areaInfo: {}
}

const mutations = {
	SET_CONFIG: (state, configInfo) => {
		state.configInfo = configInfo
	},
    SET_TAGTREE: (state, tagTree) => {
        state.tagInfo = tagTree
    },
    SET_AREA: (state, areaInfo) => {
        state.areaInfo = areaInfo
    }
}

const actions = {
	getConfig({ commit }, params) {
        return new Promise((resolve, reject) => {
            commit("SET_CONFIG", params);
            setConfig(params);
            resolve(params);
        });
    },
    // 获取V3版本兴趣列表
    getTagV3({commit}, params) {
        return new Promise(resolve => {
            commit('SET_TAGTREE', params)
            resolve(params)
        })
    },
    // 获取地区
    getArea({commit}, params) {
        return new Promise(resolve => {
            commit('SET_AREA', params)
            resolve(params)
        })
    },
    clearData({commit}) {
        return new Promise(resolve => {
            commit("SET_CONFIG", {});
            commit('SET_TAGTREE', {})
            commit('SET_AREA', {})
            resolve()
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
